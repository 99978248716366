
@font-face {
  font-family: 'arborialight';
  src: url('../assets/fonts/Arboria-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
 
 
 
 @font-face {
    font-family: 'arboria';
    src: url('../assets/fonts/Arboria-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'arboriablack';
    src: url('../assets/fonts/Arboria-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .font-apply {
    font-family: 'arborialight', Arial, sans-serif;
  }


  .zoom-page {
    zoom: 120%; 
  }
  .zoom-default {
    zoom: 90%; 
  }


  .zoom-dois {
    zoom: 110%; 
  }
  